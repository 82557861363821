import React from 'react';
import FieldDataMainContent from './components/field-data-main-content/FieldDataMainContent';
import FieldDataAsideContent from './components/field-data-aside-content/FieldDataAsideContent';

const FieldData = () => {
    return (
        <div className='flex flex-1 gap-2 overflow-hidden'>
            <div className='flex-1 overflow-y-auto'>
                <FieldDataMainContent />
            </div>
            <div className='flex w-[400px] overflow-hidden'>
                <FieldDataAsideContent />
            </div>
        </div>
    );
};

export default FieldData;
